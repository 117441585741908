import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo, FAQJsonLd } from 'gatsby-plugin-next-seo';
export default ({ data }) => {
  const { page } = data
  const { title, content, databaseId, seo, slug } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.varmalaina.com/${slug}/`}
      />
      {title === "Asuntolaina" &&
      <FAQJsonLd
        questions={[
          { question: 'Mistä voin hakea asuntolainaa?', answer: 'Voit hakea asuntolainaa valitsemaltasi pankilta. Sinun ei siis tarvitse hakea lainaa omalta pankilta pelkästään. Jos haet lainaa useasta pankista, saat melko varmasti erilaiset tarjoukset.' },
          { question: 'Voiko asuntolainan kilpailuttaa?', answer: 'Kyllä voi! Asuntolaina kannattaa aina kilpailuttaa. Varmasti jokainen haluaa huolehtia tarkoin raha-asioista, sillä se on hyvin fiksua. Mikäli kilpailutat lainan, voit säästää jopa useita tuhansia euroja.' },
          { question: 'Pystyykö asuntolainaa hakemaan netistä?', answer: 'Ilman muuta. Voit hakea asuntolainaa todella näppärästi pankin omilta verkkosivuilta. Sinun ei siis tarvitse lähteä konttorille odottamaan omaa aikaa. Kun olet lähettänyt hakemuksen menemään, pankki on yhteydessä sinuun.' },
          { question: 'Mitä kannattaa huomioida asuntolainan hakemisessa?', answer: 'Ensimmäiseksi mieti tarkkaan, kuinka paljon otat lainaa. Ikinä ei kannata hakea liian suurta asuntolainaa, mikäli et pysty maksamaan sitä hyvin. Kannattaa myös vertailla tarjouksia huolella, että saisit juuri sinulle sopivan lainan.' },
        ]}
      />
      }
      {title === "Pankkilaina" &&
      <FAQJsonLd
        questions={[
          { question: 'Voiko pankkilainaa hakea netistä?', answer: 'Kyllä voi! Pankkilainaa voi hakea netistä hyvin helposti ja yksinkertaisesti. Jos olet hakemassa isompaa lainaa, joudut käymään erikseen keskustelemassa virkailijan kanssa pankissa.' },
          { question: 'Mihin tarkoitukseen pankkilainaa voi hakea?', answer: 'Pankkilaina ei välttämättä tarvitse olla asuntoa tai esimerkiksi huvilaa varten. Voit siis hakea lainaa melkein mihin tahansa. Pankit saattavat kysyä mitä varten olet ottamassa lainaa, mutta sillä ei ole vaikutusta sen saantiin.' },
          { question: 'Kuinka nopeasti pankkilaina hyväksytään?', answer: 'Pankkilaina hyväksytään todella nopeasti, mikäli haet sitä arkipäivänä toimistoaikoina. Viikonloppuna lainoja ei tarkastella. Jos haet arkena suurta pankkilainaa, voi hyväksymisessä mennä 1 – 2 arkipäivää.' },
          { question: 'Mitä kannattaa miettiä pankkilainaa hakiessa?', answer: 'Ensimmäisenä kannattaa tehdä kunnon suunnitelma pankkilainan hakemisesta. Mieti kuinka suuri ottamasi laina on pakko olla ja pystytkö välttämättä maksamaan sen takaisin aikanaan. Ikinä ei kannata ottaa liian suurta lainaa.' },
          { question: 'Mistä löydän parhaan pankkilainan?', answer: 'Tähän ei ole suoraa vastausta. Jos haluat ottaa parhaan pankkilainan, kannattaa kilpailuttaa laina usean pankin kanssa. Näin saat mahdollisimman hyvän tarjouksen lainalle. Voit säästää jopa tuhansia euroja ja saada hyvät maksuajat lainalle.' },
        ]}
      />
      }
      {title === "Lainaa heti" &&
      <FAQJsonLd
        questions={[
          { question: 'Miten saan lainaa heti?', answer: 'Ensimmäisenä kannattaa tarkastaa lainantarjoajan käsittelyajat. Valitse siis se yhtiö, joka käsittelee lainahakemukset mahdollisimman nopeasti. Kannattaa myös itse laatia suunnitelma lainan maksuun, sillä se saattaa nopeuttaa käsittelyä.' },
          { question: 'Mitä kannattaa miettiä lainan hakemisessa?', answer: 'Ensimmäisenä kannattaa tehdä kattava suunnitelma siitä, kuinka paljon otat lainaa heti ja miten maksat ne takaisin. Ikinä ei kannata lähteä hakemaan lainaa ilman suunnitelmaa, sillä siitä voi koitua ongelmia.' },
          { question: 'Miksi vertailla lainantarjoajia?', answer: 'Jos haluat lainaa heti, kannattaa vertailla tarjoajia huolella. Korko voi olla joillain yhtiöillä todella korkea, jonka takia niitä ei kannata käyttää. Käsittelyajat ovat myös erilaiset lainantarjoajilla.' },
          { question: 'Kuinka ottaa fiksusti lainaa heti?', answer: 'Kun otat lainaa heti tilille, kannattaa miettiä maksusuunnitelma ensin. Älä siis ota semmoista lainaa, mitä et pysty maksamaan ajoissa takaisin. Muista myös aina tarkastaa se, että korot eivät ole liian suuret. Voit aina kilpailuttaa lainantarjoajat!' },
          { question: 'Ovatko kaikki netissä olevat lainantarjoajat hyviä?', answer: 'Suora vastaus tähän on, että ei. Yhtiöt saattavat pyytää todella isoja korkoja lainoista, jota ei ole missään nimessä järkeä maksaa. Muista siis ehdottomasti vertailla eri lainantarjoajia keskenään ja niiden korkoja.' },
        ]}
      />
      }
      <article
        className={`post-${databaseId} post page type-page status-publish hentry testi`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </header>
        <div className="post-inner thin container">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
